import { TFunction } from "i18next";
import { ConfirmOption, getIcon } from "~/lib/ui";

// Make sure these exist in Locize as plurals before adding
export type TEMPLATE_ENTITIES =
  | "invoice"
  | "product"
  | "product_variant"
  | "customer"
  | "project"
  | "employee"
  | "form"
  | "file"
  | "product_bundle"
  | "contact_person"
  | "credit_note"
  | "draft"
  | "offer"
  | "registration"
  | "label"
  | "template"
  | "standard_file"
  | "absence_type";

// If you need to add an action make sure that it's worded so that other entities can use it.
// If it's very specific (and you must), then use ConfirmationDialog directly instead.
type ENTITY_ACTIONS = "delete" | "ignore_unsaved_changes";

export type ConfirmationTemplateProps =
  | {
      action: "generic";
      entity?: never;
    }
  | {
      action: ENTITY_ACTIONS;
      entity: TEMPLATE_ENTITIES;
    };

export function entityConfirmationTemplate({
  entity,
  action,
  t,
}: ConfirmationTemplateProps & {
  t: TFunction;
}): ConfirmOption {
  switch (action) {
    case "delete":
      const actionTitle = t("common:delete_entity", {
        replace: { entity: t(`common:${entity}`, { count: 1 }).toLocaleLowerCase() },
      });
      return {
        title: `${actionTitle}?`,
        description: t("common:delete_description_prompt", {
          replace: { entity: t(`common:${entity}`, { count: 1 }).toLocaleLowerCase() },
        }),
        submitLabel: actionTitle,
        variant: "alert",
        Icon: getIcon("delete"),
      };

    /**
     * Developer note: Ideally, we want to automatically save changes instead of asking this.
     * But that's beyond the scope of this branch. Revisit in a future form refactor.
     */
    case "ignore_unsaved_changes":
      return {
        title: t("common:ignore_unsaved_changes_prompt", {
          replace: { entity: t(`common:${entity}`, { count: 1 }).toLocaleLowerCase() },
          defaultValue: "Ignore unsaved changes to {{entity}}?",
        }),
        description: t("common:ignore_unsaved_changes_description", {
          replace: { entity: t(`common:${entity}`, { count: 1 }).toLocaleLowerCase() },
          defaultValue:
            "You have unsaved changes to {{entity}}. Are you sure you proceed without saving them?",
        }),
        submitLabel: t("common:confirm"),
        variant: "warning",
        Icon: getIcon("warningTriangle"),
      };

    default:
      return {
        title: t("ui:confirm.title"),
        description: t("ui:confirm.description"),
        submitLabel: t("common:confirm"),
        variant: "warning",
      };
  }
}
