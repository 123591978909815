import { Invoice } from "@apacta/sdk";

/**
 * Shared visibility logic for invoices page actions
 * Note: This isn't meant to be a full-blown solution for action abstractions.
 * That's on Andri's TODO for later
 */
type Types =
  | "view-action-button"
  | "edit-action-button"
  | "save-detail-button"
  | "send-action-button"
  | "activity-log-action-button"
  | "delete-action-button"
  | "credit-action-button"
  | "book-detail-button"
  | "preview-detail-inline"
  | "preview-detail-button";

export function isVisible(action: Types, invoice: Invoice): boolean {
  const isBooked = !invoice.isDraft;
  const isDraft = !!invoice.isDraft;
  const hasIntegration = !!invoice.integrationId;
  const sentAsDraftToErp = isDraft && hasIntegration;
  const locked = !!invoice.isLocked;

  switch (action) {
    case "view-action-button":
      return isBooked && !locked;
    case "send-action-button": {
      if (isDraft || locked) {
        return false;
      }
      return true;
    }
    case "activity-log-action-button":
      return !locked;
    case "delete-action-button":
      // We can't delete invoices that are sent to an integration
      if (hasIntegration) return false;
      return isDraft && !locked;
    case "credit-action-button":
      return isBooked || sentAsDraftToErp;
    case "preview-detail-inline":
      return isBooked || sentAsDraftToErp;
    case "preview-detail-button":
      //Opposite case here
      return !isVisible("preview-detail-inline", invoice);
    case "save-detail-button":
    case "book-detail-button":
    case "edit-action-button":
      return isDraft && !locked && !hasIntegration;
    default:
      throw new Error("Invalid action type provided: " + action);
  }
}
