import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { CACHE_INVOICES } from "..";
import { ProjectStatusSelection } from "~/lib/ui/selection-combobox/project-status-selection";
import { useState } from "react";
import Switch from "~/lib/ui/switch";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { InvoiceSendFragment, SendData } from "./invoice-send-fragment";
import { useMe } from "~/lib/auth/use-me";
import { InformationBox } from "~/lib/ui/dialog/components/information-box";
import { useInvoiceActionContext } from "~/pages/invoices/_cmp/invoice-action-context";

interface BookInvoiceDialogProps {
  onClose: () => void;
}

export function BookInvoiceDialog({ onClose }: BookInvoiceDialogProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useAPI();
  const { invoice, pendingSave, invoiceState } = useInvoiceActionContext();

  const me = useMe();
  const isERP = me.integrationsEnabled.erp;

  const [sendInvoiceAfterSaving, setSendInvoiceAfterSaving] = useState(true);
  const [sendData, setSendData] = useState<SendData>({
    recipientEmail: "",
    subject: "",
    body: "",
    attachProjectOverviewPDF: false,
    isValid: false,
  });

  const mut = useMutation({
    mutationFn: async () => {
      await pendingSave?.();
      await api.editInvoice({
        invoiceId: invoice.id,
        editInvoiceRequest: {
          isDraft: isDraft,
          toErp: isERP,
          projectStatusId: formState.getValue("projectStatusId"),
        },
      });
      if (sendInvoiceAfterSaving && sendData) {
        await api.iInvoiceSendEmail({
          invoiceId: invoice.id,
          recipientEmail: sendData.recipientEmail,
          body: sendData.body,
          subject: sendData.subject,
          projectOverviewAttached: sendData.attachProjectOverviewPDF,
        });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });
      onClose();
    },
  });

  const formState = useFormState({
    schema: {
      projectStatusId: z.string().uuid(),
      type: z.enum(["draft", "invoice"]),
    },
    initialValues: {
      type: "draft", // draft by default (only used by ERP)
      projectStatusId: invoice.project.projectStatusId,
    },
    mutationError: mut.error,
  });
  // Here we only book invoice
  const isDraft = false;

  const s = invoiceState.getValues();

  const handleSendDataChange = (data: SendData) => {
    setSendData((prev) => ({ ...prev, ...data }));
  };

  return (
    <>
      <div className="mb-4 flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {pendingSave && (
            <InformationBox variant="warning">
              <div className="whitespace-pre-line">
                {t("common:unsaved_changes_will_be_saved_before_proceeding")}
              </div>
            </InformationBox>
          )}
        </div>
        <ProjectStatusSelection
          required
          label={t("invoices:change_project_status_after_booking")}
          onSelect={(v) => v && formState.onChange("projectStatusId", v)}
          defaultValue={formState.getValue("projectStatusId")}
        />
        <Switch
          defaultChecked={sendInvoiceAfterSaving}
          label={t("invoices:send_invoice")}
          onCheckedChange={(v) => setSendInvoiceAfterSaving(v)}
          error={formState.getField("projectStatusId").errors[0]}
        />
        {sendInvoiceAfterSaving && (
          <InvoiceSendFragment
            projectId={invoice.project.id}
            contactPersonId={s.contactPersonId}
            customerId={s.customerId}
            onChange={handleSendDataChange}
          />
        )}
      </div>
      <div className="flex flex-col gap-4">
        <DialogFooter
          onClose={onClose}
          primary={{
            label: t("invoices:action_book"),
            onClick: () => mut.mutateAsync(),
            disabled:
              !formState.isValid || (sendInvoiceAfterSaving && !sendData.isValid) || mut.isPending,
          }}
        />
      </div>
    </>
  );
}
