import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { useToastOnError } from "~/lib/utils/hooks";
import { Boundary } from "~/lib/ui/boundary";
import { Button, Dialog, getIcon, Icon } from "~/lib/ui";
import { InvoiceBadge } from "./_cmp/invoice-badge";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { linkToInvoiceV3, linkToProject } from "~/lib/utils";
import { InvoiceSendDialog } from "./_cmp/invoice-send-dialog";
import { CACHE_INVOICES } from ".";
import { InvoiceKpiDataQuery, useGraphQL } from "~/lib/gql";
import { BaseFile, EditInvoiceOperationRequest, Invoice } from "@apacta/sdk";
import { Badge } from "~/lib/ui/badge";
import { InvoiceFormState, useInvoiceFormState } from "./_cmp/use-invoice-formstate";
import { useCallback, useEffect, useState } from "react";

import { CACHE_PROJECTS } from "../projects";
import { useMe } from "~/lib/auth/use-me";
import { useFeatureFlags } from "~/lib/feature-flags";
import { PreviewButton } from "./_cmp/preview-button";
import { getInvoiceEntity, getInvoiceType } from "./_cmp/get-invoice-type";
import { isVisible } from "./_cmp/invoice-logic";
import BlockNavigation from "~/lib/navigation/block-navigation";
import { InvoiceActionDialog } from "~/pages/invoices/_cmp/invoice-action-dialog";
import { EanInvoiceDialog } from "~/pages/invoices/_cmp/ean-invoice-dialog";
import DuplicateInvoiceDialog from "~/pages/invoices/_cmp/duplicate-invoice-dialog";
import { useEntityFileStaging } from "~/lib/files/use-entity-file-staging";
import { useToasts } from "~/lib/toast/use-toasts";
import { usePreferences } from "~/lib/preferences/use-preferences";
import { EconomicWarningDialog } from "~/pages/invoices/_cmp/economic-warning-dialog";

const ECONOMIC_MAX_MESSAGE_LENGTH = 300;

export default function InvoicePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const { invoiceId } = useParams();
  const sdk = useGraphQL();
  const me = useMe();
  const features = useFeatureFlags();
  const { showTemplate } = useToasts();
  const [preferences, setPreferences] = usePreferences();

  const [invoiceQ, filesQ, kpiQ] = useSuspenseQueries({
    queries: [
      {
        queryKey: [CACHE_INVOICES, invoiceId],
        queryFn: () => api.iGetInvoice({ invoiceId: invoiceId as string }),
      },
      {
        queryKey: [CACHE_INVOICES, invoiceId, "files"],
        queryFn: () => api.getInvoiceFiles({ invoiceId: invoiceId as string }),
      },
      {
        queryKey: [CACHE_INVOICES, invoiceId, "kpi"],
        queryFn: () =>
          sdk.invoiceKPIData({
            id: invoiceId as string,
          }),
      },
    ],
  });

  useToastOnError(invoiceQ.error);

  const invoice = invoiceQ.data?.data;
  const files = filesQ.data?.data;
  const kpi = kpiQ.data.invoice;

  const { stageFile, unstageFile, filesToUpload, fileIdsToDelete, clearStaging, viewFiles } =
    useEntityFileStaging(files);

  const formState = useInvoiceFormState(invoice, me.company.vatPercent);

  const [bookInvoiceOpen, setBookInvoiceOpen] = useState<boolean>(false);
  const [economicWarningOpen, setEconomicWarningOpen] = useState<boolean>(false);

  // The invoice can change, so we need to update the form state so that editing isn't borked
  // React Router will not unmount if you navigate between invoices (intenteded behaviour)
  useEffect(() => {
    // TODO: This should ideally use the same init function as the useInvoiceFormState
    formState.setValues(
      {
        customerId: invoice.contactId,
        issueDate: invoice.issuedDate,
        reference: invoice.reference,
        message: invoice.message,
        paymentTermId: invoice.paymentTermId,
        lines: invoice.invoiceLines,
        contactPersonId: invoice.contactPersonId,
      },
      true // treated as initial
    );
  }, [invoice]);

  const editInvoiceMutation = useMutation({
    mutationFn: (args: EditInvoiceOperationRequest) => {
      return api.editInvoice(args);
    },
    onSuccess: async () => {
      // This makes sure the project is updated with the new invoice
      await queryClient.invalidateQueries({ queryKey: [CACHE_PROJECTS, invoice.projectId] });
      await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });
      formState.setValues(values, true);
      showTemplate("CHANGES_SAVED");
    },
  });

  const deleteInvoiceFilesMutation = useMutation({
    mutationFn: (args: { invoiceId: string; requestBody: Array<string> }) => {
      return api.deleteInvoiceFile(args);
    },
    onSuccess: () => {
      clearStaging("delete");
    },
    onError: (error) => {
      console.error("Error deleting files", error);
    },
  });

  const uploadInvoiceFilesMutation = useMutation({
    mutationFn: (args: { invoiceId: string; files: Array<File> }) => {
      return api.iInvoiceUploadFile(args);
    },
    onSuccess: () => {
      clearStaging("upload");
    },
    onError: (error) => {
      console.error("Error uploading files", error);
    },
  });

  const values = formState.getValues();
  const handleSave = useCallback(async () => {
    // Delete files first (if any)
    if (fileIdsToDelete.length) {
      await deleteInvoiceFilesMutation.mutateAsync({
        invoiceId: invoice.id,
        requestBody: fileIdsToDelete,
      });
    }

    // Upload files second (if any)
    if (filesToUpload.length) {
      await uploadInvoiceFilesMutation.mutateAsync({
        invoiceId: invoice.id,
        files: filesToUpload,
      });
    }

    // Save the rest of the invoice
    await editInvoiceMutation.mutateAsync({
      invoiceId: invoice.id,
      editInvoiceRequest: {
        contactId: values.customerId ?? null, // null unselects
        contactPersonId: values.contactPersonId ?? null, // null unselects
        issuedDate: values.issueDate,
        reference: values.reference,
        message: values.message,
        invoiceLines: values.lines,
        paymentTermId: values.paymentTermId,
        vatPercent: values.vatPercent,
      },
    });

    // This makes sure the project is updated with the new invoice
    await queryClient.invalidateQueries({ queryKey: [CACHE_PROJECTS, invoice.projectId] });
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });

    formState.setValues(values, true);
  }, [invoice, values, filesToUpload, fileIdsToDelete]);

  const isModified = formState.isModified;
  const filesModified = !!filesToUpload.length || !!fileIdsToDelete.length;
  const pendingSave = isModified || filesModified ? handleSave : undefined;

  if (!invoice) return null;

  // Invoice with number if any, otherwise just "Invoice"
  const title = (() => {
    const baseTitle = getInvoiceType({ invoice, t });

    if (invoice?.invoiceNumber) {
      return `${baseTitle} #${invoice?.invoiceNumber}`;
    }

    return getInvoiceType({ invoice, t });
  })();

  // Creates a draft of a new invoice with the values reversed
  async function handleCreateCreditNote() {
    const res = await api.duplicateInvoice({
      invoiceId: invoice.id,
      asCreditNote: true,
    });

    if (!res.data.id) return;
    showTemplate("CREATED");
    navigate(linkToInvoiceV3(res.data.id));
  }

  const showSendButton = isVisible("send-action-button", invoice);
  const showBookButton =
    !features.has("locked_invoices") && isVisible("book-detail-button", invoice);
  const showSaveButton = isVisible("save-detail-button", invoice);
  const showPreviewButton = isVisible("preview-detail-button", invoice);
  const showCreditNoteButton = isVisible("credit-action-button", invoice);
  const showSyncToERPandLock = invoice.isDraft && features.has("locked_invoices");

  const handleFileStaging = (f: Array<File>) => {
    stageFile(f);
  };

  const handleFileRemoval = (file: File | BaseFile | null) => {
    if (!file) return;
    unstageFile(file);
  };

  const navigationBlocked =
    formState.isModified || !!filesToUpload.length || !!fileIdsToDelete.length;
  const saveDisabled =
    (!formState.isModified && !filesToUpload.length && !fileIdsToDelete.length) ||
    editInvoiceMutation.isPending;
  const showEconomicMessageWarning =
    me.integrations.erp === "economic" &&
    !!formState.getValue("message") &&
    formState.getValue("message")!.length > ECONOMIC_MAX_MESSAGE_LENGTH &&
    !preferences.dismissEconomicMessageLengthWarning;

  const handleOpenBookInvoice = () => {
    if (showEconomicMessageWarning) {
      setEconomicWarningOpen(true);
      return;
    }
    setBookInvoiceOpen(true);
  };

  const handleEconomicAccept = (dismiss: boolean) => {
    if (dismiss) {
      setPreferences("dismissEconomicMessageLengthWarning", true);
    }
    setEconomicWarningOpen(false);
    setBookInvoiceOpen(true);
  };

  return (
    <PageLayout
      title={title}
      renderDescription={() => (
        <div className="flex flex-col gap-2">
          <OptionalLink to={linkToProject(invoice.project.id)}>
            <div className="flex flex-row items-center gap-2">
              <Icon name="project"></Icon>
              <div>{invoice?.project?.name}</div>
            </div>
          </OptionalLink>

          <div className="flex flex-row items-center gap-2">
            <InvoiceBadge invoice={invoice} />
            <Badge variant="yellow" icon={invoice.project.isFixedPrice ? "currency" : "time"}>
              {invoice.project.isFixedPrice
                ? t("projects:fixed_price")
                : t("projects:variable_price")}
            </Badge>
          </div>
        </div>
      )}
      renderActions={() => (
        <div className="flex flex-row gap-4">
          {showSaveButton && (
            <Button onClick={handleSave} disabled={saveDisabled} variant="tertiary">
              {t("common:save_x", {
                replace: {
                  x: t(`common:${getInvoiceEntity(invoice)}`, { count: 1 }).toLocaleLowerCase(),
                },
                defaultValue: "Save {{x}}",
              })}
            </Button>
          )}
          {showPreviewButton && (
            <PreviewButton
              variant="primary"
              preview={{ fileUrl: invoice.pdfUrl }}
              unsavedChanges={formState.isModified}
              onSaveChanges={handleSave}
            />
          )}

          {showBookButton && (
            <>
              <Dialog
                trigger={
                  <Button Icon={getIcon("invoice")} variant="primary" disabled={!!invoice.erpId}>
                    {t("invoices:ean_invoice", { defaultValue: "EAN invoice" })}
                  </Button>
                }
                render={({ onClose }) => (
                  <EanInvoiceDialog invoice={invoice} onClose={onClose} pendingSave={pendingSave} />
                )}
              />
              <Button
                Icon={getIcon("invoice")}
                onClick={handleOpenBookInvoice}
                variant="primary"
                disabled={!!invoice.erpId}
              >
                {me.integrationsEnabled.erp
                  ? t("invoices:export_to_erp")
                  : t("invoices:action_book", "Book")}
              </Button>
              <Dialog
                open={bookInvoiceOpen}
                onOpenChange={setBookInvoiceOpen}
                render={({ onClose }) => (
                  <InvoiceActionDialog
                    invoice={invoice}
                    onClose={onClose}
                    invoiceState={formState}
                    pendingSave={pendingSave}
                  />
                )}
              />
            </>
          )}

          {showSendButton && (
            <Dialog
              trigger={
                <Button variant="primary" Icon={getIcon("send")}>
                  {t("invoices:send_invoice")}
                </Button>
              }
              render={({ onClose }) => (
                <InvoiceSendDialog
                  invoiceState={formState}
                  onClose={onClose}
                  invoice={invoice}
                  pendingSave={pendingSave}
                />
              )}
            />
          )}
          {showCreditNoteButton && (
            <Button variant="secondary" Icon={getIcon("add")} onClick={handleCreateCreditNote}>
              {t("invoices:create_credit_note")}
            </Button>
          )}
          {showSyncToERPandLock && (
            <Button
              Icon={getIcon("lock")}
              variant="secondary"
              confirm={{
                action: "generic",
              }}
              disabled={
                invoice.isLocked ||
                editInvoiceMutation.isPending ||
                !formState.getValue("customerId")
              }
              title={
                !formState.getValue("customerId")
                  ? t("invoices:sync_to_erp_and_lock_requirements", "You need to select a customer")
                  : undefined
              }
              onClick={async () => {
                // Save any pending changes before locking
                await pendingSave?.();
                // Lock the invoice
                await editInvoiceMutation.mutateAsync({
                  invoiceId: invoice.id,
                  editInvoiceRequest: {
                    isDraft: false,
                    isLocked: true,
                  },
                });
              }}
            >
              {t("invoices:sync_to_erp_and_lock")}
            </Button>
          )}
          <Dialog
            triggerAsChild
            trigger={
              <Button Icon={getIcon("duplicate")} variant="secondary">
                {t("invoices:copy_to_project")}
              </Button>
            }
            render={({ onClose }) => (
              <DuplicateInvoiceDialog pendingSave={pendingSave} onClose={onClose} />
            )}
          />
        </div>
      )}
    >
      <Boundary variant="tab">
        <Outlet
          context={{
            invoice,
            viewFiles,
            kpi,
            formState,
            pendingSave,
            handleFileStaging,
            handleFileRemoval,
          }}
        />
      </Boundary>
      <Dialog
        open={economicWarningOpen}
        onOpenChange={setEconomicWarningOpen}
        render={({ onClose }) => (
          <EconomicWarningDialog onClose={onClose} onSubmit={handleEconomicAccept} />
        )}
      />
      <BlockNavigation when={navigationBlocked} onSaveBeforeNavigate={pendingSave} />
    </PageLayout>
  );
}

export type InvoiceOutlet = {
  invoice: Invoice;
  viewFiles: Array<File | BaseFile>;
  kpi: InvoiceKpiDataQuery["invoice"];
  formState: InvoiceFormState;
  pendingSave?: () => Promise<void>;
  handleFileStaging: (f: Array<File>) => void;
  handleFileRemoval: (file: File | BaseFile | null) => void;
};
