import { Row } from "@tanstack/react-table";
import { AbsenceType, EditAbsenceTypeRequest } from "@apacta/sdk";
import { Button, LabelInput } from "~/lib/ui";
import { useFormState } from "~/lib/form-state";
import z from "zod";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CACHE_ABSENCE_TYPES } from "~/pages/settings/index/absence";
import { useEffect } from "react";

export function UpdateAbsenceTypeRow({
  row,
  onIsModifiedChange,
}: {
  row: Row<AbsenceType>;
  onIsModifiedChange: (isModified: boolean) => unknown;
}) {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const updateAbsenceTypeMutation = useMutation({
    mutationFn: async (data: EditAbsenceTypeRequest) => api.editAbsenceType(data),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_ABSENCE_TYPES],
      });
      onIsModifiedChange(false);
      row.toggleExpanded(false);
    },
  });

  const initialValues = {
    name: row.original.name,
    salaryNo: row.original.salaryId,
  };

  const formState = useFormState({
    schema: {
      name: z.string().min(1),
      salaryNo: z.string().optional(),
    },
    initialValues,
    mutationError: undefined,
  });

  const handleUpdateAbsenceType = () => {
    return updateAbsenceTypeMutation.mutateAsync({
      absenceTypeId: row.original.id as string,
      createAbsenceTypeRequest: {
        name: formState.getValue("name"),
        salaryId: formState.getValue("salaryNo"),
      },
    });
  };

  const handleCancelForm = () => {
    formState.reset(initialValues);
    row.toggleExpanded(false);
  };

  useEffect(() => {
    onIsModifiedChange(formState.isModified);
  }, [formState.isModified]);

  return (
    <div className="flex gap-8">
      <div className="w-4/12">
        <LabelInput label={t("common:salary_no")} {...formState.registerStringInput("salaryNo")} />
      </div>
      <div className="w-4/12">
        <LabelInput label={t("common:name")} {...formState.registerStringInput("name")} />
      </div>
      <div className="flex grow justify-end gap-2 self-end">
        <Button
          variant="tertiary"
          size="small"
          disabled={!formState.isValid || !formState.isModified}
          onClick={handleUpdateAbsenceType}
        >
          {t("common:save")}
        </Button>
        <Button variant="secondary" size="small" onClick={handleCancelForm}>
          {t("common:cancel")}
        </Button>
      </div>
    </div>
  );
}
