import { useAPI } from "~/lib/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AbsenceType } from "@apacta/sdk";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { getExpandedRowModel } from "@tanstack/react-table";
import { CreateAbsenceTypeDialog } from "~/pages/settings/index/_cmp/create-absence-type-dialog";
import { UpdateAbsenceTypeRow } from "~/pages/settings/index/_cmp/update-absence-type-row";
import { useState } from "react";
import BlockNavigation from "~/lib/navigation/block-navigation";

export const CACHE_ABSENCE_TYPES = "absence-types";

export default function AbsencePage() {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [numberOfPendingRows, setNumberOfPendingRows] = useState(0);

  const deleteAbsenceTypeMutation = useMutation({
    mutationFn: async (id: string) => {
      await api.deleteAbsenceType({ absenceTypeId: id });
      await queryClient.invalidateQueries({ queryKey: [CACHE_ABSENCE_TYPES] });
    },
  });

  const columns = useDataColumns<AbsenceType>((columnHelper) => [
    columnHelper.accessor("salaryId", {
      id: "salary_id",
      enableSorting: true,
      header: t("common:salary_no"),
      meta: { className: "w-4/12 group" },
      cell: ({ row }) => row.original.salaryId,
    }),
    columnHelper.accessor("name", {
      enableSorting: true,
      header: t("common:name"),
      meta: { className: "w-8/12 group" },
      cell: ({ row }) => row.original.name,
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      meta: { className: "flex grow justify-end self-end" },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              onClick: () => deleteAbsenceTypeMutation.mutateAsync(row.original.id as string),
              confirm: {
                action: "delete",
                entity: "absence_type",
              },
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState({
    sorting: [{ id: "name", desc: false }], // defaults
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_ABSENCE_TYPES,
      tableState.state.pagination,
      tableState.state.search,
      tableState.sortBy,
      tableState.sortingDirection,
    ],
    queryFn: async () =>
      api.getAbsenceTypes({
        page: tableState.pageNumber,
        q: tableState.state.search,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      isLoading: dataQ.isLoading,
      data: dataQ.data?.data ?? [],
      singleRowExpansion: true,
      getRowId: (row) => row.id,
      backendPagination: dataQ.data?.pagination,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand: () => true,
      enableGlobalFilter: true,
    }
  );

  return (
    <div>
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("common:absence_type", { count: 2 })}</h2>
        <div>
          <Dialog
            render={({ onClose }) => <CreateAbsenceTypeDialog onClose={onClose} />}
            trigger={
              <Button variant="tertiary" Icon={getIcon("add")}>
                <span>
                  {t("common:create", {
                    entity: t("common:absence_type", { count: 1 }).toLowerCase(),
                  })}
                </span>
              </Button>
            }
          />
        </div>
      </div>
      <DataTable
        error={dataQ.error}
        table={table}
        renderExpandedRow={({ row, onHasPendingChanges }) => (
          <UpdateAbsenceTypeRow
            row={row}
            onIsModifiedChange={(isModified) => {
              const newCount = onHasPendingChanges(isModified);
              setNumberOfPendingRows(newCount);
            }}
          />
        )}
      />
      <BlockNavigation when={numberOfPendingRows > 0} />
    </div>
  );
}
