import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon, LabelInput } from "~/lib/ui";
import { DialogBody } from "~/lib/ui/dialog/dialog-body";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateAbsenceTypeRequest } from "@apacta/sdk";
import { CACHE_ABSENCE_TYPES } from "~/pages/settings/index/absence";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import z from "zod";
import { useTranslation } from "react-i18next";

export function CreateAbsenceTypeDialog({ onClose }: { onClose: () => unknown }) {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const formState = useFormState({
    schema: {
      name: z.string().min(1),
      salaryNo: z.string().optional(),
    },
    initialValues: {
      name: "",
      salaryNo: undefined,
    },
    mutationError: undefined,
  });

  const createAbsenceTypeMutation = useMutation({
    mutationFn: async (data: CreateAbsenceTypeRequest) =>
      api.createAbsenceType({ createAbsenceTypeRequest: data }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_ABSENCE_TYPES] });
      onClose();
    },
  });

  const handleCreateAbsenceType = () => {
    return createAbsenceTypeMutation.mutateAsync({
      name: formState.getValue("name"),
      salaryId: formState.getValue("salaryNo"),
    });
  };

  return (
    <div>
      <DialogHeader
        title={t("common:create", {
          entity: t("common:absence_type", { count: 1 }).toLowerCase(),
        })}
        Icon={getIcon("time")}
      />
      <DialogBody>
        <div className="flex flex-col gap-6">
          <LabelInput {...formState.registerStringInput("name")} label={t("common:name")} />
          <LabelInput
            {...formState.registerStringInput("salaryNo")}
            label={t("common:salary_no")}
          />
        </div>
      </DialogBody>
      <DialogFooter
        primary={{
          label: t("common:create_nondistinct"),
          onClick: handleCreateAbsenceType,
          disabled: !formState.isValid,
        }}
        onClose={onClose}
      />
    </div>
  );
}
