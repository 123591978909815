import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Outlet } from "react-router";
import { Suspense } from "react";
import { getIcon } from "~/lib/ui";

export default function SettingsPage() {
  const { t } = useTranslation();

  return (
    <PageLayout title={t("settings:title")}>
      <PageTabs
        tabs={[
          {
            id: "company",
            label: t("settings:tabs.company"),
            Icon: getIcon("company"),
            to: "",
          },
          {
            id: "templates",
            label: t("settings:tabs.templates"),
            Icon: getIcon("masterData"),
            to: "templates/",
          },
          {
            id: "labels",
            label: t("settings:tabs.labels"),
            Icon: getIcon("template"),
            to: "labels/",
          },
          {
            id: "files",
            label: t("settings:tabs.files"),
            Icon: getIcon("file"),
            to: "files/",
          },
          {
            id: "absence",
            label: t("common:absence_type", { count: 2 }),
            Icon: getIcon("time"),
            to: "absence/",
          },
        ]}
      />
      <Suspense>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
